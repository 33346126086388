Lyte.Component.register("lyte-notes", {
_template:"<template tag-name=\"lyte-notes\"> <div class=\"lyteNotes {{ltPropNoteType}}Note\"> <p class=\"docContent\"> <lyte-yield yield-name=\"notes\"></lyte-yield> </p> </div> </template>\n<style>lyte-notes {\n  display: block;\n}\n.lyteNotes{\n  padding: 10px 25px 10px 30px;\n  /* border-radius: 6px; */\n  margin: 15px 0px;\n}\n.lyteNotes .docContent {\n  margin: 10px 0;\n}\n.successNote{\n  background: var(--cruxdoc-notes-success-bg);\n  border-left: var(--cruxdoc-notes-success-border);\n}\n.failureNote{\n  background: var(--cruxdoc-notes-failure-bg);\n  border-left: var(--cruxdoc-notes-success-border);\n}\n.warningNote{\n  background: var(--cruxdoc-notes-warning-bg);\n  border-left: var(--cruxdoc-notes-warning-border);\n}\n</style>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"insertYield","position":[1,1,1]}],
_observedAttributes :["ltPropNoteType"],

	data : function(){
		return {
			'ltPropNoteType' : Lyte.attr('string' , {
				'default' : 'success'
			})
		}
	}
});
