Lyte.Component.register("doc-snippet-wrapper", {
_template:"<template class=\"docSnippetWrapper\" tag-name=\"doc-snippet-wrapper\"> <lyte-tabs lt-prop-height=\"auto\"> <template is=\"registerYield\" yield-name=\"tabYield\"> <lyte-yield yield-name=\"codeYield\"></lyte-yield> </template> </lyte-tabs> </template>\n<style>doc-snippet-wrapper {\n  /* max-height: 400px; */\n  display: block;\n  margin: 50px 0px;\n}\ndoc-snippet-wrapper lyte-tab-body {\n  padding: 0;\n}\ndoc-snippet-wrapper .lyteTabDefaultTop lyte-tab-head{\n  background: var(--cruxdoc-doc-snippet-wrapper-bg);\n  border-bottom-color: transparent;\n}\ndoc-snippet-wrapper doc-snippet{\n  margin-top: 10px;\n  display: block;\n}\ndoc-snippet-wrapper lyte-tab-body{\n  background: var(--cruxdoc-doc-snippet-bg);\n}\ndoc-snippet-wrapper .lyteTabDefaultTop lyte-tab-title:hover,\ndoc-snippet-wrapper .lyteTabDefaultTop lyte-tab-title {\n  background: inherit;\n  color: #aaa;\n  border-bottom-color: transparent;\n  font-size: 16px;\n  transition: 0.3s;\n}\ndoc-snippet-wrapper .lyteTabDefaultTop lyte-tab-title:hover{\n  color: #ccc;\n}\ndoc-snippet-wrapper .lyteTabDefaultTop lyte-tab-title.lyteTabActive:hover,\ndoc-snippet-wrapper .lyteTabDefaultTop lyte-tab-title.lyteTabActive {\n  border-bottom-color: transparent;\n  color: #fff;\n  transition: 0.3s;\n  background: inherit;\n  font-weight: normal;\n}\n.nightMode doc-snippet-wrapper lyte-tab-body{\n  background: #333;\n}\n.nightMode doc-snippet-wrapper .lyteTabDefaultTop lyte-tab-head{\n  background: #333;\n  border-bottom-color: transparent;\n}\n</style>",
_dynamicNodes : [{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"insertYield","position":[1]}]},{"type":"componentDynamic","position":[1]}],

	// didConnect : function(){
		// var codeSnipArr = this.$node.querySelectorAll('doc-snippet');
		// var heightArr = [];
		// for(var i=0;i<codeSnipArr.length;i++){
		// 	var snipHeight = codeSnipArr[i].getBoundingClientRect().height;
		// 	if(snipHeight === 0){
		// 		codeSnipArr[i].parentNode.classList.remove('lyteTabHide');
		// 		snipHeight = codeSnipArr[i].getBoundingClientRect().height;
		// 		if(i !== 0){
		// 			codeSnipArr[i].parentNode.classList.add('lyteTabHide');
		// 		}
		// 	}
		// 	heightArr.push(snipHeight);
		// }
		// var maxHeight = heightArr[0];
		// for(var j=1; j < heightArr.length; j++) {
		// 	if(heightArr[j] > maxHeight) {
		// 		maxHeight = heightArr[j];
		// 	}
		// }
		// maxHeight += 36;
		// if(maxHeight > 300) {
		// 	maxHeight = 300;
		// }
		
		// if(maxHeight < 100){
		// 	maxHeight = 100;
		// }
		// var setMaxHt = maxHeight + 'px';
		// this.setData('maxHeight' , setMaxHt);
	// }
});
