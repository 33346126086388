Lyte.Component.register("cruxdoc-request", {
_template:"<template tag-name=\"cruxdoc-request\"> <lyte-search lt-prop-query-selector=\"{&quot;scope&quot; : &quot;tbody&quot;, &quot;search&quot; : &quot;td:first-of-type&quot;, &quot;target&quot; : &quot;tr&quot;}\" lt-prop-width=\"300px\" class=\"requestSerach\" lt-prop-appearance=\"box\" lt-prop-placeholder=\"Search existing requirements\"></lyte-search> <table class=\"requestTable\"> <thead><tr><th class=\"requestName\">Requirement Name</th><th class=\"requestDescription\">Description</th><th>Owner</th><th>Requirement raised by</th><th>Stage</th></tr></thead> <tbody> <tr is=\"for\" lyte-for=\"true\" items=\"{{json}}\" item=\"data\" index=\"index\" depth=\"2\"></tr> </tbody> </table> <div id=\"xhrResponse\"></div> </template>",
_dynamicNodes : [{"type":"componentDynamic","position":[1]},{"type":"attr","position":[3,3,1]},{"type":"for","position":[3,3,1],"dynamicNodes":[{"type":"text","position":[1,1,0]},{"type":"text","position":[1,2,0]},{"type":"text","position":[1,4,0]},{"type":"text","position":[1,6,0]},{"type":"attr","position":[1,6,2]},{"type":"if","position":[1,6,2],"cases":{"true":{"dynamicNodes":[{"type":"text","position":[1]}]}},"default":{}},{"type":"text","position":[1,8,0]}],"actualTemplate":"<template is=\"for\" items=\"{{json}}\" item=\"data\" index=\"index\" depth=\"2\"><table><tbody> <tr> <td class=\"requestName\">{{data[\"Requirement Name\"]}}</td><td class=\"requestDescription\">{{data.Description}}</td> <td>{{data[\"Requirement Owner\"]}}</td> <td>{{data[\"Request raised by\"]}}<template is=\"if\" value=\"{{data.Product}}\"><template case=\"true\">({{data.Product}})</template></template></td> <td>{{data[\"Stages New\"]}}</td> </tr> </tbody></table></template>","tagName":"TBODY"}],
_observedAttributes :["json"],

	data : function(){
		return {
			json : Lyte.attr("array")
		}		
	},
	didConnect : function(){
		
	}
});