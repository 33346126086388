Lyte.Mixin.register("right-panel", {//No I18n
	setRightPanelData : function(){
		var rightPanelData = [] ;

		if(this.transition.info.dynamicParams[1] === "overview" ){
			rightPanelData = document.getElementById("content").children[0].getData("sections");
		} else if(this.transition.info.dynamicParams[1] === "api"){
			rightPanelData = this.getApiSectionsOf(childComp.getData("apisections"));
		}
		if(this.transition.info.route.indexOf('introduction') !== -1 ){
			rightPanelData = document.getElementById("content").children[0].getData("sections");
		}
		var rightPanelTreeData =[];
		if(rightPanelData && rightPanelData.length){
			rightPanelTreeData = [{"name" : "t"}];
			rightPanelTreeData[0].children = this.processTreeDataConversion(rightPanelData);
			/*
			function treeDataConverstion(tempArr){
				if(tempArr){
				var treeTempArr = [];
				for(var i=0;i<tempArr.length;i++){
					var tempTreeObj = {};
					if(typeof(tempArr[i]) === "string"){
						tempTreeObj.name = tempArr[i];
						treeTempArr.push(tempTreeObj);
					}
					if(typeof(tempArr[i]) === "object"){
					  tempTreeObj.name = tempArr[i].name;
					  tempTreeObj.children = treeDataConverstion(tempArr[i].children);
					  treeTempArr.push(tempTreeObj);
					}
				}
					return treeTempArr;
				}
			}
				*/
		}
		if(!this.transition.ignoreScroll){
			document.getElementsByTagName("crmcomp-component")[0].setData("rightPanelData", rightPanelTreeData);			
		}
	},
	processTreeDataConversion: function (tempArr) {
		if (tempArr) {
			var treeTempArr = [];
			for (var i = 0; i < tempArr.length; i++) {
				var tempTreeObj = {};
				if (typeof (tempArr[i]) === "string") {
					tempTreeObj.name = tempArr[i];
					treeTempArr.push(tempTreeObj);
				}
				if (typeof (tempArr[i]) === "object") {
					tempTreeObj.name = tempArr[i].name;
					tempTreeObj.children = this.processTreeDataConversion(tempArr[i].children);
					treeTempArr.push(tempTreeObj);
				}
			}
			return treeTempArr;
		}
	},
	getApiSectionsOf :function(data){
		var apisections= [],tempObj={}
			if(data){
				if( data.properties && Object.keys(data.properties).length > 0){
					 tempObj = {};
					 tempObj.name = "Properties";
					 tempObj.children = Object.keys(data.properties).map(function(prop){
											 return prop.replace("cxProp","").split("").reduce(function(acc,char){
					 							return acc+(char.toUpperCase()===char?" "+char:char);
					 						});
					 					});

					 apisections.push(tempObj);
				}
				if(data.methods && Object.keys(data.methods).length > 0){
					tempObj = {};
					tempObj.name = "Methods";
					tempObj.children = Object.keys(data.methods);
					apisections.push(tempObj);
				}
				if(data.utilityFunctions && Object.keys(data.utilityFunctions).length > 0){
					tempObj = {};
					tempObj.name = "Utility Functions";
					tempObj.children = Object.keys(data.utilityFunctions);
					apisections.push(tempObj);
				}
				if(data.events && Object.keys(data.events).length > 0){
					tempObj = {};
					tempObj.name = "Events";
					tempObj.children = Object.keys(data.events);
					apisections.push(tempObj);
				}
				if(data.callbacks && Object.keys(data.callbacks).length > 0) {
					tempObj = {};
					tempObj.name = "Callbacks";
					tempObj.children = Object.keys(data.callbacks);
					apisections.push(tempObj);
				}
				if(data.helpers && Object.keys(data.helpers).length > 0) {
					tempObj = {};
					tempObj.name = "Helpers";
					tempObj.children = Object.keys(data.helpers);
					apisections.push(tempObj);
				}
		}
		document.getElementById("content").children[0].setData("apisections",apisections);
		return apisections;
	},
	setOwner : function(transition){
		if(transition.info.dynamicParams[0] == "tag" && transition.info.route.indexOf("elements") > -1){
			$L('doc-panel')[0].component.setData('theDeveloper' , $L("cruxdoc-"+transition.info.dynamicParams[0]+"-component")[0].getData("owner"));
		}
		else{
	        $L('doc-panel')[0].component.setData('theDeveloper' , $L("cruxdoc-"+transition.info.dynamicParams[0])[0].getData("owner"));
		}
	}
});
