Lyte.Component.register("page-header", {
_template:"<template tag-name=\"page-header\"> <div class=\"main-heading\"> <div class=\"tab-details\"> <span class=\"pageHeaderTab active\" data-tabvalue=\"overview\" onclick=\"{{action('settingSwitch',event,'overview')}}\">Overview</span> <span class=\"pageHeaderTab\" onclick=\"{{action('settingSwitch',event,'api')}}\" data-tabvalue=\"api\">API</span> <template is=\"if\" value=\"{{hasDesignMode}}\"><template case=\"true\"> <span class=\"pageHeaderTab\" data-tabvalue=\"customize\" onclick=\"{{action('settingSwitch',event,'customize')}}\">Customize</span> </template></template> </div> </div> </template>\n<style>.tab-details {\n\tdisplay: flex;\n\tpadding: 0 90px;\n}\n.pageHeaderTab {\n\tcolor: #555;\n\tpadding: 9px 20px;\n\tcursor: pointer;\n\tline-height: 20px;\n\tflex-grow: 1;\n\ttext-align: center;\n\tfont-size: 16px;\n}\n.pageHeaderTab:hover {\n\tcolor: #111;\n}\n.pageHeaderTab.active {\n\tcolor: #111;\n\tbackground: #ecf5f5;\n\tcursor: default;\n}\n</style>",
_dynamicNodes : [{"type":"attr","position":[1,1,1]},{"type":"attr","position":[1,1,3]},{"type":"attr","position":[1,1,5]},{"type":"if","position":[1,1,5],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]}]}},"default":{}}],
_observedAttributes :["hasDesignMode"],

	data: function(){
		return{
			"hasDesignMode" : Lyte.attr("boolean",{default:false})
		}
	},
	toggleActiveClass:function(type){
		var _this = this;
		window.requestAnimationFrame( function (){
			window.requestAnimationFrame( function (){
				_this.$node.querySelectorAll("span").forEach(function(el){
					el.classList.remove("active");
				});
				var elem = _this.$node.querySelector("span[data-tabvalue="+type);

				if(elem) {
					elem.classList.add("active");
				}
			});
		});
	},
	actions : {
		settingSwitch: function (event,type) {
			Lyte.Router.getRouteInstance().setDynamicParam(type);
			this.toggleActiveClass(type);
			var docContainer = document.querySelector('crmcomp-component');
			docContainer.setData('blockScrollSpy' , true);
			document.querySelector('section').scrollTop = 0;
		}
	}
});
