Lyte.Component.register("heterogenous-array", {
_template:"<template tag-name=\"heterogenous-array\"> <span class=\"modalHeader heading\">{{parseName(propertyName)}}</span> <div class=\"modalBody\"> <lyte-input lt-prop-type=\"textarea\" lt-prop-appearance=\"box\" lt-prop-style=\"padding:10px;max-height:600px;overflow:auto\" onpaste=\"{{action('resizeTextArea',event)}}\" onkeydown=\"{{action('resizeTextArea',event)}}\" lt-prop-id=\"lyteinput\" lt-prop-rows=\"5\" lt-prop-placeholder=\"Enter your array here\" lt-prop-cols=\"80\" lt-prop-value=\"{{lbind(value)}}\" lt-prop-disabled=\"{{disabled}}\" lt-prop-text-area-resize=\"{&quot;vertical&quot; : false, &quot;horizontal&quot; : false}\"> </lyte-input> <template is=\"if\" value=\"{{errorMsg}}\"><template case=\"true\"><div class=\"errorMsg\">{{errorMsg}}</div></template></template> <lyte-button lt-prop-disabled=\"{{disabled}}\" style=\"float:right\" lt-prop-style=\"height: 30px;margin:10px 15px 0px;padding: 0px 7px;width: auto;\" lt-prop-background-color=\"#1BCC88\" onclick=\" {{action('isValidJson')}}\"> <template is=\"registerYield\" yield-name=\"text\"> <span>Update</span> </template> </lyte-button> </div> </template>",
_dynamicNodes : [{"type":"text","position":[1,0]},{"type":"attr","position":[3,1]},{"type":"componentDynamic","position":[3,1]},{"type":"attr","position":[3,3]},{"type":"if","position":[3,3],"cases":{"true":{"dynamicNodes":[{"type":"text","position":[0,0]}]}},"default":{}},{"type":"attr","position":[3,5]},{"type":"registerYield","position":[3,5,1],"dynamicNodes":[]},{"type":"componentDynamic","position":[3,5]}],
_observedAttributes :["value","errorMsg","propertyName","propertyData","disabled"],

	data : function(){
		return {	
			value:Lyte.attr("string",{default:""}),
			errorMsg:Lyte.attr("string",{default:""}),
			propertyName:Lyte.attr("string"),
			propertyData:Lyte.attr("object",{default:{}}),
			disabled:Lyte.attr("boolean",{default:false})
		}		
	},
	didConnect:function(){
		this.resizeTextArea();
	},
	valueObserver:function(){
		var componentName = document.querySelector("property-tree").getData("componentName"),
			propertyName = this.getData("propertyName"),
			value = this.getData("propertyData").defaultValue,
			data = JSON.parse(document.querySelector("code-area").component.getData("renderContent"));
		var convertToHtmlAttr = function(string){
			return string.split("").map(function(ch){
				if(ch.toUpperCase()==ch){
					return "-"+ch.toLowerCase();
				}else{
					return ch;
				}
			}).join("");
		}
		var getOptions = function(array,systemValue) {
			var output = [];
			array.forEach(function(item){
			switch(typeof item){
				case "string":
					output.push(item);
				break;
				case "object":
					for(var key in item){
						switch(typeof item[key]){
							case "string":
							if((systemValue&&key===systemValue)||(!systemValue && key==="value")){
								output.push(item[key]);
							}
							break;
							case "object":
								if(Array.isArray(item[key])){
									item[key].forEach(function(subItem){
										switch(typeof subItem){
											case "string":
												output.push(subItem);
											break;
											case "object":
												for(var subkey in subItem){
													if((systemValue&&subkey===systemValue)||(!systemValue && subkey==="value")){
														output.push(subItem[subkey]);
													}
												}
											break;
										}
									});
								}
							break;
						}
					}
				break;
			}
		});
		return output;
		}
		var arrayToString = function(array){
			return "["+array.map(function(element){
				return '"'+element.toString()+'"';
			})+"]"
		}
		this.setData("disabled",false);
		propertyName = convertToHtmlAttr(this.getData("propertyName"));
		data = data.child[0].attr[propertyName];
		if(data){
			value = JSON.parse(Array.isArray(data)?data.join(""):data);
			this.setData("value",value?value:'[""]');
			this.isValidJson();	
		}else{
			this.setData("value",value?value:'[""]');
		}
	}.observes("propertyData"),
	resizeTextArea:function(){
		var element = this.$node.querySelector("textarea");
		setTimeout(function(){
			element.style.padding = "10px";
			element.style.height = "1em";
		    element.style.height = element.scrollHeight + 'px';
		},0);
	},
	triggerMethod: function(method,propertyName,data) {
		if(this.getMethods(method)) {
			this.executeMethod(method,propertyName,data);
			document.querySelector("property-tree property-item[property-name="+propertyName+"]").setData("currentValue",data);
		}
		var componentName = document.querySelector("property-tree").getData("componentName");
		var warningConditions = document.querySelector("property-tree").getData("warningConditions");
		if(warningConditions && componentName in warningConditions && propertyName in warningConditions[componentName]){
			document.querySelector("property-item").component.showWarning(warningConditions[componentName][propertyName]());
		}
	},
	isValidJson:function(){
		let findSelectionStartAndEndFrom = function(position,string,element){
			var selectionStart = position,
				selectionEnd = position;
			if(string[position]!=="\n"){
				do{
					selectionStart--;
				}while(string[selectionStart]!=="\n" && selectionStart>=0)
				do{
					selectionEnd++;
				}while(string[selectionEnd]!=="\n" && selectionEnd<=string.length)
			element.focus();
			element.setSelectionRange(selectionStart+1,selectionEnd);
			}else{
				findSelectionStartAndEndFrom(position-1,string,element);
			}
		}
		var elem = this.$node.querySelector("lyte-input");
		var value = elem.ltProp("value");
		try{
			value = JSON.stringify(JSON.parse(value),null,'\t');
			this.resizeTextArea();
			if(this.data.propertyData.type == "object"){
				this.setData("errorMsg","");
				this.triggerMethod('onDataChange',this.getData("propertyName"),JSON.parse(value));	
			}
			else if(!Array.isArray(JSON.parse(value))){
				this.setData("errorMsg","Enter a value of datatype array");
			}else{
				this.setData("errorMsg","");
				this.triggerMethod('onDataChange',this.getData("propertyName"),JSON.stringify(JSON.parse(value)));
			}
			elem.ltProp("value",value);
		}catch(e){
			elem.querySelector("textarea").classList.add("dataError");
			if(/at position (\d+)/g.test(e)){
				var position = parseInt(/at position (\d+)/g.exec(e)[1]);
				findSelectionStartAndEndFrom(position,value,elem.querySelector("textarea"));
			}
			this.setData("errorMsg",e.message);
		}
	},
	actions:{
		resizeTextArea:function(event){
			var elem = this.$node.querySelector("lyte-input textarea");
			if(event.key==="Tab" && !event.shiftKey){
				event.preventDefault();
				var element = this.$node.querySelector("textarea");
				var oldStart = element.selectionStart;
	            var before   = element.value.substring(0, element.selectionStart);
	            var selected = element.value.substring(element.selectionStart, element.selectionEnd);
	            var after    = element.value.substring(element.selectionEnd);
	            element.value = before + "    " + selected + after;
	            element.selectionEnd = oldStart + 4;
			}
			elem.classList.remove("dataError");
			this.setData("errorMsg","");
			this.resizeTextArea();
		},
		isValidJson:function(){
			this.isValidJson();	
		}
	}
});
