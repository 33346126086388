Lyte.Component.register("fun-des", {
_template:"<template tag-name=\"fun-des\"> <div class=\"jsonParseDiv\"> <table class=\"parseTable\" cellpadding=\"0\" cellspacing=\"0\"> <tbody><tr is=\"for\" lyte-for=\"true\" items=\"{{funDesObj}}\" item=\"item\" index=\"index\" depth=\"2\"></tr> </tbody></table> </div> </template>\n<style>.jsonParseDiv {\n  padding: 10px 20px;\n  font-size: 14px;\n  border-radius: 6px;\n  margin: 20px 0;\n  border: 1px solid #ccc;\n}\n.jsonParseDiv .objKey {\n  color: #444;\n  margin-right: 10px;\n  text-transform: capitalize;\n  font-size: 15px;\n}\n.jsonParseDiv td {\n  padding: 8px 0;\n    vertical-align: top;\n}\n\n.jsonParseDiv .objVal {\n  color: #111;\n  margin-left: 10px;\n    display: inline-block;\n    font-size: 15px;\n}\n\n\n/* Night Mode Css */\n\n.nightMode .jsonParseDiv{\n  border:1px solid #000;\n}\n.nightMode .objKey,\n.nightMode .parseTable td{\n  color: #fff;\n}\n.nightMode .objVal{\n  color: #eee;\n}\n</style>",
_dynamicNodes : [{"type":"attr","position":[1,1,1,0]},{"type":"for","position":[1,1,1,0],"dynamicNodes":[{"type":"text","position":[0,1,1,0]},{"type":"text","position":[0,5,1,0]}],"actualTemplate":"<template items=\"{{funDesObj}}\" item=\"item\" index=\"index\" is=\"for\" depth=\"2\"><table><tbody><tr> <td> <span class=\"objKey\">{{item.key}}</span> </td> <td>:</td> <td> <span class=\"objVal\">{{unescape(item.val)}}</span> </td> </tr></tbody></table></template>","tagName":"TBODY"}],
_observedAttributes :["ltPropData","funDesObj"],

	data : function(){
		return {
			'ltPropData' : Lyte.attr('object' , {
				'default' : {}
			}),
			'funDesObj' : Lyte.attr('array' , {
				'default' : []
			})
		}
	},
	dataChange : function(){
		var obj = this.getData('ltPropData');
		if(obj) {
			var str = '';
			var objarr = Object.keys(obj);
			var valarr = Object.values(obj);
			var tempArr = [];
			for(var i=0;i<objarr.length;i++){
				var myObj = {};
				myObj.key = objarr[i];
				myObj.val = valarr[i];
				tempArr.push(myObj);
			}
			this.setData('funDesObj' , tempArr);
		}
	}.observes("ltPropData").on("didConnect")
});
