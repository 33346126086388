Lyte.Component.registerHelper("idHelper", function (name, capitalize) {
	while (name && typeof name == "object" && name.name) {
		name = name.name;
	}
	if (name != undefined && name != "") {
		return name.replace(/ /g, "_").replace(/\?/g, "")
	}
});
Lyte.Component.registerHelper("getSectionValue", function (name) {
	while (name && typeof name == "object" && name.name) {
		name = name.name;
	}
	if (name != undefined && name != "") {
		return name;
	}
});
Lyte.Component.registerHelper("num", function (str) {
	return parseInt(str);
});
Lyte.Component.registerHelper("parseName", function (name) {
	return name ? name.replace("ltProp", "").split("").map(function (ch) {
		return (/[A-za-z]/.test(ch) && ch.toUpperCase() === ch) ? " " + ch : ch
	}).join("").trim() : "";
});
Lyte.Component.registerHelper("checkIncludes", function (array, element) {
	if (!array || !element) {
		return false;
	} else {
		return array.includes(element);
	}
});
Lyte.Component.registerHelper("toString", function (str) {
	if (str) {
		if (typeof str === "object") {
			return JSON.stringify(str);
		} else {
			return str.toString();
		}
	} else {
		return "";
	}
});
Lyte.Component.registerHelper('length', function (input) {
	if (Array.isArray(input)) {
		return input.length;
	} else if (typeof input === "object") {
		return Object.keys(input).length;
	}
	return "";
});