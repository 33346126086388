Lyte.Mixin.register("elements-properties", {
	cxPropFrom : {description : "To determine what the element has to be displayed or where it is to be used.",
				type : "string", tabs : [{title : "Example", content : "cx-prop-from='create'"}],
				allowedValues : ["view", "criteria", "create"]},
	cxPropField : {description : "It being a mandatory property, helps to provide the attributes such as data type, UI, type.",
				type : "object", tabs : [{title : "Example", content : "cx-prop-field='{\"required\" : \"true\", \"field_label\":\"Field Label\" , \"data_type\" : \"text\", \"ui_type\":\"1\"}'"}], skipInDocs : true},
	cxPropZcqa : {description : "Used for testing purposes.",
				type : "string", tabs : [{title : "Example", content : "cx-prop-zcqa='QA_zcqa'"}], skipInDocs : true},
	cxPropIgnoreEmptyValue : {description : "Validation will be skipped even if the value is empty.",
				type : "boolean", defaultValue : false, allowedValues : ["true", "false"], tabs : [{title : "Example", content : "cx-prop-ignore-empty-value=true"}], skipInDocs : true},
	cxPropClearErrorMessage : {description : "This property determines if the error message should be cleared on change.",
				type : "boolean", defaultValue : true, tabs : [{title : "Example", content : "cx-prop-clear-error-message=false"}],
				skipInDocs : true,
				allowedValues : ["true", "false"]},
	cxPropClass : {description : "The css class that needs to be set to the input.",
				type : "string", tabs : [{title : "Example", content : "cx-prop-class='wrapperClass'"}]},
	cxPropDisabled : {description : "This property disables input. lyteInputDisabled class will be added to lyte-input.",
				type : "boolean", defaultValue : false, tabs : [{title : "Example", content : "cx-prop-disabled=true"}],
				allowedValues : ["true", "false"]},
	cxPropReadonly : {description : "It makes the input field as readonly.",
				type : "boolean", defaultValue : false, tabs : [{title : "Example", content : "cx-prop-readonly=true"}],
				allowedValues : ["true", "false"]},

	cxPropAppearance : {description : "It defines the appearance of the element.",
				type : "string", defaultValue : "flat", tabs : [{title : "Example", content : "cx-prop-appearance='box'"}],
				allowedValues : ["flat", "box"]},
	cxPropErrorMessage : {description : "This property can be set to display an error message on validation failure.", type : "string", tabs : [{title : "Example",
				content : "cx-prop-error-message='This is an error message'"}]},
	cxPropMaxlength : {description : "Sets maximum length for the element.", type : "number", tabs : [{title : "Example",
				content : "cx-prop-maxlength=16"}]},
	getValue : {description : "Will return the value of the input.", tabs : [{title : "Return value",
				content : ["value (string)"]}]},
	validate : {description : "To determine if the given input is valid.", tabs : [{title : "Return value", content : ["true/false"]}]},
	cxPropPlaceholder : {description : "The text thats needs to be displayed as placeholder if value is not present.",
				type : "string", tabs : [{title : "Example", content : "cx-prop-placeholder='This is an email field placeholder'"}]},
	cxPropFieldKey : {description : "If you want to display the input box along with a label, you need to pass the property in cxPropField that will be the label.",
type : "string", tabs : [{title : "Example", content : "cx-prop-field-key='field_label'"}]},
cxPropLabelClass : {description : "If you are displaying the field label you can pass a class to it as well.", type : "string", tabs : [{title :"Example", content : "cx-prop-label-class='wrapperClass'"}]},
cxPropEmptyValue : {description : "If there is no cxPropValue you can choose to display a default value which we call the empty value.", type : "string", tabs : [{title : "Example",
content :"cx-prop-empty-value='-'"}]},
cxPropTooltip : {description :"To display a tooltip in the input", type : "string", tabs :[{title : "Example", content : "cx-prop-tooltip='This is a tooltip'"}]},
cxPropErrorYield : {description : "You can choose to display your own error message using yield.", type : "boolean", yieldName : "errorYield", defaultValue : false,
tabs : [{title : "Example", content : "cx-prop-error-yield=true"}],
allowedValues : ["true", "false"]},
cxPropInfoTooltip : {description : "You can set an info icon with a tooltip next to the field label.", type : "string", tabs : [{title : "Example", content : "cx-prop-info-tooltip='Info tooltip'"}]},
cxPropAria : {description : "To enable aria properties.", type : "boolean", defaultValue : false, tabs : [{title : "Example", content : "cx-prop-aria=true"}],
allowedValues : ["true", "false"]},
cxPropAriaAttributes : {description : "To determine aria attributes.", type : "object", tabs : [{title : "Example", content : "cx-prop-aria-attributes = '{\"aria-value\" : \"some value\"}'"}]},
cxPropTooltipConfig : {description : "Configurations for tooltip.", type : "object", defaultValue : '{"position": "followcursor", "appearance": "box"}', tabs : [{title : "Example", content : "lt-prop-tooltip-config = '{\"position\" : \"followcursor\", \"appearance\" : \"box\" }'"}]},
cxPropTooltipClass : {description : "Same class will be added for tooltip created.", type : "string", tabs : [{title : "Example", content : "cx-prop-tooltip-class='wrapperClass'"}]},
cxPropErrorZcqaPrefix : {description : "You can set a prefix to the zcqa.", type : "string", tabs : [{title : "Example", content : "cx-prop-error-zcqa-prefix='errorPrefix'"}]},

cxPropErrorZcqaSuffix : {description : "You can set a suffix to the zcqa.", type : "string", tabs : [{title : "Example", content : "cx-prop-error-zcqa-suffix='errorSuffix'"}]},
cxPropBoundary : {description : "It represents a rectangular area(dimensions calculated from the window) beyond which the dropdown closes. When the lyte-drop-button crosses this boundary(by scrolling), it automatically closes the dropdown.",
type : "object", tabs : [{title : "Example", content : "cx-prop-boundary='{\"left\":\"300\",\"bottom\":\"500\"}'"}]},
cxPropTabIndex : {description : "It sets tab index for input.", type : "string", tabs : [{title : "Example", content : "cx-prop-tab-index=1"}]},
cxPropAutocomplete : {description : "Enables native autocomplete property for input.", type : "string", defaultValue : "on", allowedValues : ["on", "off"], tabs : [{title : "Example", content : "cx-prop-auto-complete='on'"}]},
cxPropFreeze : {description : "Same will be applied for dropdown associated with lyte-input. If it sets true dropdown will be opened with freeze layer", type : "boolean",
defaultValue : false, tabs : [{title : "Example", content : "cx-prop-freeze=true"}]},
onError : {description : "This is triggered when validation provided by the element fails. You can use this to overwrite the elements' default behavior of displaying an error message.",
tabs : [{title : "Arguments", content : ["error code", "element component"]}]},
onValueChange : {description : "Triggered whenever the value is changed.", tabs : [{title :"Arguments", content : ["new value"]}]},
cxPropMinDate : {description : "	The minimum boundary or date beyond which it is selectable. All dates behind this date are greyed out and are not selectabled. This should adhere to lt-prop-format. It can also be combined with lt-prop-max-date.",
		type : "string", tabs : [{title : "Example", content : "cx-prop-min-date='12/12/2017'"}]},
		cxPropMaxDate : {description : "The maximum boundary or date beyond which it is not selectable. All dates beyond this date are greyed out and are not selectabled. It should adhere to lt-prop-format. It can also be combined with lt-prop-min-date.",
		type : "string", tabs : [{title : "Example", content : "cx-prop-max-date='12/12/2021'"}]},
		cxPropDropdownProperties : {description : "The maximum boundary or date beyond which it is not selectable. All dates beyond this date are greyed out and are not selectabled. It should adhere to lt-prop-format. It can also be combined with lt-prop-min-date.",
		type : "object", defaultValue : JSON.stringify({"preventScroll":"body"}), tabs : [{title : "Example", content : "cx-prop-dropdown-properties='{\"preventScroll\" : \"body\"}'"}]},
		cxPropHeaderType : {description : "This is used to set the header type of the navigation bar. It can either be a set of normal nav buttons or a dropdown.",
		type : "string", defaultValue : "dropdown", allowedValues : ["default", "drilldown", "dropdown"], tabs : [{title : "Example", content : "cx-prop-header-type='dropdown'"}]},
		cxPropDirection : {description : "It defines how label and input field placed.",
		type : "string", defaultValue : "vertical", allowedValues : ["vertical", "horizontal"], tabs : [{title : "Example", content : "cx-prop-direction='horizontal'"}]},
		cxPropMaskingProperties : {description : "You can choose to mask the value of the element by passing this property.", type : "object", tabs : [{title : "Example", content : "cx-prop-masking-properties='{\"length\" : \"5\", \"character\" : \"*\"}'"}]},
		cxPropEnableLbind : {description : "If its true 'lt-prop-value' will be updated on every input with 250ms debounce( In this case you can take current value from inner 'input' tag ) or else it will get updated in blur event.",
		type : "boolean", defaultValue : true, allowedValues : ["true", "false"], tabs : [{title : "Example", content : "cx-prop-auto-update=false"}]},
		cxPropId : {description : "Sets id to the child element.", type : "string", tabs : [{title : "Example", content : "cx-prop-id='sampleId'"}]},
		cxPropName : {description : "Sets name to the child element.", type : "string", tabs : [{title : "Example", content : "cx-prop-name='sampleName'"}]},
		focus : {description : "You can call this function for triggering manual focus on input. Use focus function with 100 ms settimeout delay on animations."},
		resize : {description : "used to resize the alignment according to page size."},
		reset : {description : "This util is  called to reset the height of the component."},
		resetData : {description : "This util is called to reset the component state."},
		refresh : {description : "This util  is used to get  the data after a refresh."},
		close : {description : "This util is used to close the popup."},
		cxPropInputClass : {description : "This class is set to lt-prop-class of the lyte-input.", type : "string", tabs : [{title : "Example", content : "cx-prop-input-class='sampleClass'"}]},
		cxPropLayout : {description : "This is used to fetch layout specific properties like required and picklistvalues from the field.", type : "string", tabs : [{title : "Example", content : "cx-prop-layout='standardlayoutid'"}]},
		cxPropMandatory : {description : "It overwrites layout specific required property.", type : "boolean", allowedValues : ["true", "false"], tabs : [{title : "Example", content : "cx-prop-mandatory=true"}]},
		cxPropViewInfoTooltip : {description : "When passed as true, an info icon is displayed next to the field label and hover of it displays a hovercard", type : "boolean", tabs : [{title : "Example", content : "cx-prop-view-info-tooltip=true"}],
		allowedValues : ["true", "false"]},
		
		cxPropErrorClass : {description : "This class is set to the crux-error-message element.", type : "string", tabs : [{title : "Example", content : "cx-prop-error-class='sampleClass'"}]},
		cxPropErrorSpanClass : {description : "This class is set to the span tag inside the crux-error-message.", type : "string", tabs : [{title : "Example", content : "cx-prop-error-span-class='sampleClass'"}]},
		cxPropShowWarning : {description : "When set to true, it displays a warning message similar to an error message.", type : "boolean", tabs : [{title : "Example", content : "cx-prop-show-warning=true"}],
		allowedValues : ["true", "false"]},

		cxPropShowWarningIconClass : {description : "When warning is displayed, this is the class that will be set to the icon rendered before the message.", type : "string", tabs : [{title : "Example", content : "cx-prop-show-warning-icon-class='sampleClass'"}]},
		cxPropWarningMessage : {description : "When warning is displayed, this is the message that is rendered.", type : "string", tabs : [{title : "Example", content :"cx-prop-warning-message='WarningMessage'"}]},
		getProperties : function(){
			var props = ["cxPropAppearance", "cxPropClass", "cxPropDisabled", "cxPropEmptyValue", "cxPropFrom", "cxPropInputClass", "cxPropLayout", 
				"cxPropMandatory", "cxPropReadonly", "cxPropViewInfoTooltip","cxPropZcqa", "cxPropAria", "cxPropAriaAttributes", "cxPropClearErrorMessage",
				"cxPropErrorClass", "cxPropErrorMessage", "cxPropErrorSpanClass", "cxPropErrorYield", "cxPropErrorZcqaPrefix", "cxPropErrorZcqaSuffix", "cxPropField", 
				"cxPropFieldKey", "cxPropInfoTooltip", "cxPropLabelClass", "cxPropShowWarning", "cxPropShowWarningIconClass", "cxPropWarningMessage", 
				"cxPropTooltip", "cxPropTooltipClass", "cxPropTooltipConfig"]
			var ret = {};
			for(var i=0; i<props.length; i++){
				ret[props[i]] = this[props[i]];
			}
			return ret;
		},
		getUtilityFunctions : function(){
			return {getValue : this.getValue, validate : this.validate}
		},
		getTemplate : function(op){
			var opt = {default : {html : {node : "root", child : [{node : "element", tag : op.tag, attr : op.attr}]}}};
			if(op.showButton){
				opt.showButton = true;
			}
			return opt
		}
});