Lyte.Component.register("cruxdoc-helpers-introduction", {
_template:"<template tag-name=\"cruxdoc-helpers-introduction\"> <meta-wrap meta=\"{{metaInfo}}\"></meta-wrap> <div class=\"docIntroContainer\"> <h1-wrap lt-prop-name=\"Introduction\"></h1-wrap> <template items=\"{{sections}}\" item=\"section\" index=\"index\" is=\"for\"><section> <span class=\"scrollSpan\" id=\"{{idHelper(section)}}\"></span> <h3 class=\"docSubheadings\">{{section}}</h3> <template value=\"{{idHelper(section)}}\" is=\"switch\">   <template case=\"Getting_Started\"> <p class=\"docContent\">Crm provides you commonly used reusable helpers to help you to speedup your development in Crm. These helpers come handy when you want to perform an operation on some data from your component's template directly. You can invoke these helpers from your component's template. </p> </template><template case=\"Usage\"> <doc-snippet lt-prop-language=\"javascript\"><template is=\"registerYield\" yield-name=\"code\"><pre><code>\n                      &lt;lyte-messagebox lt-prop-message=\"{{unescape('{{getI18n('success')}}')}}\"&gt; &lt;/lyte-messagebox&gt;\n                  </code></pre></template></doc-snippet> </template></template> </section></template> </div> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"componentDynamic","position":[1]},{"type":"componentDynamic","position":[3,1]},{"type":"attr","position":[3,3]},{"type":"for","position":[3,3],"dynamicNodes":[{"type":"attr","position":[0,1]},{"type":"text","position":[0,3,0]},{"type":"attr","position":[0,5]},{"type":"switch","position":[0,5],"cases":{"Getting_Started":{"dynamicNodes":[]},"Usage":{"dynamicNodes":[{"type":"registerYield","position":[1,0],"dynamicNodes":[{"type":"text","position":[0,0,1]}]},{"type":"componentDynamic","position":[1]}]}},"default":{}}]}],
_observedAttributes :["sections"],

	data : function(){
		return {
			sections : Lyte.attr("array" , {default : ["Getting Started","Usage"]})
		}
	}
});
