Lyte.Component.register("release-notes", {
_template:"<template tag-name=\"release-notes\"> <div class=\"releaseParent\"> <h2>Release notes</h2> <p class=\"docContent\">We are committed to shipping new features without breaking your existing applications</p> <p class=\"docContent\"> </p><h3>Latest Release: {{latestReleaseVersion}}</h3> <p></p> <template is=\"for\" items=\"{{notes}}\" item=\"note\" index=\"index\"> <div class=\"releaseNotes\"> <h4>{{note.version}}</h4> <table> <tbody is=\"for\" lyte-for=\"true\" items=\"{{note.notes}}\" item=\"item\" index=\"index\" depth=\"2\"></tbody> </table> <hr> </div> </template> </div> </template>\n<style>.releaseParent{\n\tpadding : 50px\n}\n.releaseNotes{\n\tpadding-top: 30px;\n\twidth: fit-content;\n}\n.releaseNotes tr{\n\tpadding-top: 5px;\n}\n.releaseNotes .tag{\n    border: 1px solid;\n    margin: 10px;\n    padding-left: 5px;\n    padding-right: 5px;\n    background: rgb(204, 192, 192);\n    padding-bottom: 3px;\n}\n.componentName{\n\tfont-size: 20px;\n}\n.note{\n\tfont-size: 16px;\n\tpadding-top: 5px;\n}\n.releaseNotes hr{\n\tmargin-top: 5px;\n}</style>",
_dynamicNodes : [{"type":"text","position":[1,6,1]},{"type":"attr","position":[1,10]},{"type":"for","position":[1,10],"dynamicNodes":[{"type":"text","position":[1,1,0]},{"type":"attr","position":[1,3,1]},{"type":"for","position":[1,3,1],"dynamicNodes":[{"type":"text","position":[1,1,0,0]},{"type":"text","position":[1,1,1,0]},{"type":"text","position":[1,3,0]}],"actualTemplate":"<template is=\"for\" items=\"{{note.notes}}\" item=\"item\" index=\"index\" depth=\"2\"><table><tbody> <tr> <td><span class=\"componentName\">{{item.component}}</span><span class=\"tag\">{{item.type}}</span></td> <td class=\"note\">{{item.note}}</td> </tr> </tbody></table></template>","tagName":"TABLE"}]}],
_observedAttributes :["latestReleaseVersion","notes"],

	data : function(){
		return {
			latestReleaseVersion : Lyte.attr("string", {default : "3.112.0"}),
			notes : Lyte.attr("array", {default : []})
		}		
	},
	actions : {
		// Functions for event handling
	},
	methods : {
		// Functions which can be used as callback in the component.
	},
	init : function(){
		this.data.notes.push({
			version : "unknown",
			notes : [{
				component : "crux-dropdown",
				type : "new",
				note : "cxPropErrorYield, cxPropErroClass, cxPropErrorSpanClass"
			},
			{
				component : "crux-layout-component",
				type : "new",
				note : "cxPropDisabled"
			},
			{
				component : "cruxMentions",
				type : "new",
				note : "Multiple mentions support"
			},
			{
				component : "crux-alert",
				type : "fix",
				note : "Icon width issue fixed"
			},
			{
				component : "crux-user-component",
				type : "new",
				note : "supports multi select user data under the field's api_name along with the users key"
			},
			{component : "crux-territory", type : "fix", note : "multiple dropdown search issue fixed"},
			{component : "crux-picklist-component", type : "fix", note : "once a value is selected the internal property should be removed incase the data is reused"},
			{component : "crux-table-component", type : "new", note : "cxPropScrollRecordCount"},
			{component : "crux-smart-filter", type : "new", note : "getSelectedFields util added"},
			{component : "crux-dropdown", type : "new", note : "new argument added to before select callback in dropdown"},
			{component : "crux-lookup-component", type : "new", note : "'cxPropTooltipShow' property added to hide the tooltip while showing bc"},
			{component : "crux-note", type : "new", note : "disable attachment actions added"},
			{component : "crux-lookup-component", type : "new", note : "cxPropQueryParam"},
			{component : "crux-text-area-component", type : "new", note : "if increaseHeight util called from outside, should be executed"},
			{component : "crux-smart-filter", type : "new", note : "observer added for cxPropFields in filter"},
			{component : "crux-territory", type : "new", note : "remove territory and add territory node added"},
			{component : "crux-user-component", type : "new", note : "callback for hide"}
			]
		})
	}
});
