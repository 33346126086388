Lyte.Component.register("comp-set", {
_template:"<template tag-name=\"comp-set\"> <fun-des lt-prop-data=\"{{data}}\"></fun-des> <lyte-yield yield-name=\"comps\"></lyte-yield> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"componentDynamic","position":[1]},{"type":"insertYield","position":[3]}],
_observedAttributes :["ltPropData","data"],

	data : function(){
		return {
			'ltPropData' : Lyte.attr('object' , {
				'default' : {}
			}),
			data : Lyte.attr("object", {default:{}})
		}
	},
	init : function(){
		for(var key in this.data.ltPropData){
			if(key == "type"){
				this.data.data["Data Type"] = this.data.ltPropData[key];
			}
			else if(key == "tabs" || key == "heterogenousArray" || key == "skipInDocs" || key == "children" || key == "mixin_example"){
				continue;
			}
			else if(this.data.ltPropData[key] == false){
				this.data.data[key] = "false";
			}
			else if(key == "allowedValues"){
				this.data.ltPropData.tabs.push({title : "Options", options : this.data.ltPropData.allowedValues});
			}
			else{
				this.data.data[key] = this.data.ltPropData[key];
			}
		}
	}
});
