Lyte.Component.register("h1-wrap", {
_template:"<template tag-name=\"h1-wrap\"> <div class=\"pR anchorPresent\"> <template is=\"if\" value=\"{{expHandlers(ltPropDisableLink,'!')}}\"><template case=\"true\"><a class=\"docAnchor\" href=\"#{{ltPropId}}\" onclick=\"{{action('copyLink')}}\"></a></template></template> <h1 id=\"{{ltPropId}}\" class=\"pageHeading\" style=\"{{ltPropStyle}}\" onclick=\"{{action('setHref')}}\">{{ltPropName}}</h1> </div> </template>\n<style>h1-wrap h1{\n  font-size: 42px;\n}\n.pageHeading{\n  font-weight: var(--cruxdoc-page-heading-font-weight);\n  font-size: var(--cruxdoc-page-heading-font-size);\n}\n</style>",
_dynamicNodes : [{"type":"attr","position":[1,1]},{"type":"if","position":[1,1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[0]}]}},"default":{}},{"type":"attr","position":[1,3],"attr":{"style":{"name":"style","dynamicValue":"ltPropStyle"}}},{"type":"text","position":[1,3,0]}],
_observedAttributes :["ltPropName","ltPropId","ltPropStyle","ltPropDisableLink"],

	data : function() {
		return {
			'ltPropName': Lyte.attr('string'),
			'ltPropId': Lyte.attr( 'string'),
			'ltPropStyle' : Lyte.attr( 'string'),
			'ltPropDisableLink' : Lyte.attr("boolean",{default:false})
		}		
	},
	init : function() {
		this.data.ltPropId = this.data.ltPropId || this.data.ltPropName; 
	},
	actions : {
		copyLink : function() {
			var el = document.createElement('textarea');
			el.value = document.location.href;
			el.setAttribute('readonly', '');
			el.style = {position: 'absolute', left: '-9999px'};
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
		},
		setHref : function() {
			if(!this.getData("ltPropDisableLink")){
				document.location.hash = this.data.ltPropId;
				document.querySelector('#'+this.data.ltPropId).scrollIntoView();
			}
		},
		openModal:function(id){
			$L(id)[0].ltProp("show",true);
		}
	}
});
