Lyte.Component.register("doc-dependencies", {
_template:"<template tag-name=\"doc-dependencies\"> <doc-snippet lt-prop-language=\"markup\" lt-prop-file-name=\"Dependencies\"> <template is=\"registerYield\" yield-name=\"code\"> <pre>             <code>\n               <template is=\"if\" value=\"{{dependencies[&quot;lyte&quot;]}}\"><template case=\"true\">\n   &lt;!-- Below are the list of Lyte files to be loaded. Please visit the lyte documentation to fetch a list of dependencies of each of them --&gt;\n<template is=\"for\" items=\"{{dependencies[&quot;lyte&quot;]}}\" item=\"file\" index=\"index\">lyte/{{file}}</template>\n</template></template><template is=\"if\" value=\"{{dependencies[&quot;lyte-ui-components&quot;]}}\"><template case=\"true\">\n&lt;!-- Below are the list of lyte-ui-component files to be loaded. Please visit the lyte documentation to fetch a list of dependencies of each of them --&gt;\n\n<template is=\"for\" items=\"{{dependencies[&quot;lyte-ui-components&quot;]}}\" item=\"file\" index=\"index\"><br>lyte-ui-components/{{file}}\n</template></template></template><template is=\"if\" value=\"{{dependencies[&quot;lyte-ui-components&quot;]}}\"><template case=\"true\">\n&lt;!-- Below are the list of crux-components files to be loaded. Please visit each of the crux components to fetch all the dependencies --&gt;\n\n</template></template><template is=\"for\" items=\"{{dependencies[&quot;crux-components&quot;]}}\" item=\"file\" index=\"index\">crux-components/{{file}}\n</template><template is=\"if\" value=\"{{dependencies.others}}\"><template case=\"true\">\n&lt;!-- other dependencies --&gt;\n<template is=\"for\" items=\"{{dependencies.others}}\" item=\"file\" index=\"index\">\n{{file}}</template></template></template>\n             </code>\n         </pre> </template> </doc-snippet> </template>\n<style>.openInNewTab{\n\tbackground: url('images/lyteDocImagesSprite.png') no-repeat -122px -238px;\n\twidth: 34px;\n\theight: 34px;\n}</style>",
_dynamicNodes : [{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"attr","position":[1,1,1]},{"type":"if","position":[1,1,1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"for","position":[1],"dynamicNodes":[{"type":"text","position":[1]}]}]}},"default":{}},{"type":"attr","position":[1,1,2]},{"type":"if","position":[1,1,2],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"for","position":[1],"dynamicNodes":[{"type":"text","position":[2]}]}]}},"default":{}},{"type":"attr","position":[1,1,3]},{"type":"if","position":[1,1,3],"cases":{"true":{"dynamicNodes":[]}},"default":{}},{"type":"attr","position":[1,1,4]},{"type":"for","position":[1,1,4],"dynamicNodes":[{"type":"text","position":[1]}]},{"type":"attr","position":[1,1,5]},{"type":"if","position":[1,1,5],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"for","position":[1],"dynamicNodes":[{"type":"text","position":[1]}]}]}},"default":{}}]},{"type":"componentDynamic","position":[1]}],

	data : function(){
		return {

		}		
	}
});
