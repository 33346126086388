Lyte.Component.register("cruxdoc-utils-introduction", {
_template:"<template tag-name=\"cruxdoc-utils-introduction\" id=\"sectionContent\"> <meta-wrap meta=\"{{metaInfo}}\"></meta-wrap> <div class=\"docIntroContainer\"> <h1-wrap lt-prop-name=\"Introduction\"></h1-wrap> <template items=\"{{sections}}\" item=\"section\" index=\"index\" is=\"for\"><section> <span class=\"scrollSpan\" id=\"{{idHelper(section)}}\"></span> <h3 class=\"docSubheadings\">{{section}}</h3> <template value=\"{{idHelper(section)}}\" is=\"switch\">  <template case=\"Usage\"> <p class=\"docContent\">Include the util file and use using _cruxUtils.utilName();</p> <doc-snippet lt-prop-language=\"javascript\"><template is=\"registerYield\" yield-name=\"code\"><pre><code>\n                        _cruxUtils.showCustomAlert({params : {ltPropPrimaryMessage : 'Custom Alert Message'}});\n                    </code></pre></template></doc-snippet> </template></template> </section></template> </div> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"componentDynamic","position":[1]},{"type":"componentDynamic","position":[3,1]},{"type":"attr","position":[3,3]},{"type":"for","position":[3,3],"dynamicNodes":[{"type":"attr","position":[0,1]},{"type":"text","position":[0,3,0]},{"type":"attr","position":[0,5]},{"type":"switch","position":[0,5],"cases":{"Usage":{"dynamicNodes":[{"type":"registerYield","position":[3,0],"dynamicNodes":[]},{"type":"componentDynamic","position":[3]}]}},"default":{}}]}],
_observedAttributes :["sections"],

	data : function(){
		return {
			sections : Lyte.attr("array" , {default : ["Usage"]})
		}		
	}
});
